.topbar{
    display: flex;
    flex-direction: row;
    background-color: var(--topbar-background-color);
    justify-content: space-between;
    align-items: flex-end;
    overflow: hidden;
    padding-left: 2%;
    padding-right: 2%;
}

.brand-name{
    font-family: 'Pacifico', cursive;
    text-align: center;
    color: var(--text-color-gray);
    background-color: var(--tab-color);
    text-decoration: none;
}

.nav-bar{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.nav-item{
    font-family: var(--tab-font);    
    color: var(--text-color-gray);
    font-weight: 800;
    height: 100%;
    cursor: pointer;
}

.tab{
    height: max-content;
    background-color: var(--tab-color);
}

@media (orientation:portrait){
    .topbar{
        height: 10vh;
    }

    .brand-name{
        font-size: clamp(0.5rem, 1.0vw + 0.8rem, 3.0rem);
        padding-left: 1%;
        padding-right: 1%;
        border-top-left-radius: 1vw;
        border-top-right-radius: 1vw;
        width: 40%;
    }

    .nav-bar{
        width: 100%;
    }

    .nav-item{
        font-size: clamp(0.5rem, 1.0vw + 0.6rem, 1.5rem);
        padding-left: 1%;
        padding-right: 1%;
        border-top-left-radius: 1vw;
        border-top-right-radius: 1vw;
        width: 100%;
        padding-bottom: 1vh;
        transform: translate(0, 1vh);
        text-align: center;
    }

    .tab{
        padding-left: 2%;
    }

    .tab:hover{
        transform: translate(0vw, 0vh);
    }
}

@media (orientation:landscape){
    .topbar{
        height: 10vh;
        gap: .5vw;
    }

    .brand-name{
        font-size: clamp(1.0rem, 1vw + 1.0rem, 3.0rem);
        padding-left: 1%;
        padding-right: 1%;
        border-top-left-radius: 1vw;
        border-top-right-radius: 1vw;
        width: 30%;
    }

    .nav-bar{
        width: 100%;
    }

    .nav-item{
        font-size: clamp(0.5rem, 1vw + 1.0rem, 1.5rem);
        padding-left: 1%;
        padding-right: 1%;
        border-top-left-radius: 1vw;
        border-top-right-radius: 1vw;
        width: 100%;
        padding-bottom: 1vh;
        transform: translate(0, 1vh);
    }

    .tab{
        padding-left: 2%;
    }

    .tab:hover{
        transform: translate(0vw, 0vh);
    }
}