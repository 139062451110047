:root{
  --text-color-gray: #707070;
  --tab-color: #FFFFFF;
  --body-color: #ffeba5;
  --topbar-background-color: #9fedff;
  --tab-font: 'IBM Plex Sans Arabic';
  --forum-font: 'IBM Plex Sans Arabic';
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 320px;
}
