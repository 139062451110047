.home{
    display: flex;
    background-color: var(--body-color);
}

.container{
    border-top-left-radius: 1vw;
    border-top-right-radius: 1vw;
}

.newarticlefeed-container{
    background-color: white;
}

.replyfeed-container{
    background-color: white;
}

.feed-title{
    font-family: var(--forum-font);
    text-align: center;
    color: var(--text-color-gray);
    background-color: #ddf8ff;
    font-size: clamp(0.5rem, 1vw + 0.6rem, 3.0rem);
    border-top-left-radius: 1vw;
    border-top-right-radius: 1vw;
    font-weight: 800;
}

@media (orientation: portrait){
    .home{
        flex-direction: column;
        gap: 1vh;
        height: 80vh;
        padding-top: 5vh;
        padding-bottom: 5vh;
        padding-left: 10%;
        padding-right: 10%;
    }

    .newarticlefeed-container{
        height: 65%;
    }
    
    .replyfeed-container{
        height: 25%;
    }
}

@media (orientation: landscape){
    .home{
        flex-direction: row;
        gap: 1vh;
        height: 80vh;
        padding-top: 5vh;
        padding-bottom: 5vh;
        padding-left: 2%;
        padding-right: 2%;
    }
    
    .newarticlefeed-container{
        width: 75%;
        height: 100%;
    }
    
    .replyfeed-container{
        width: 25%;
        height: 100%;
    }
}