#new-articles-feed{
    display: flex;
    flex-direction: column;
}

.new-articles-feed-item{
    text-decoration: none;
    color: var(--text-color-gray);
    background-color: white;
}

.new-articles-feed-item:hover{
    background-color: var(--text-color-gray);
    color: white;
}